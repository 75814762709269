// 타입 2개 (input-box input-line)
// 돋보기 유무(t f)
// 라벨여부(t f)
// 크기(md lg)

// 상태 4개 (enable focused active error)

import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { TYPO } from "styles/Typo";

type inputType = "box" | "line";

interface inputProps {
  value?: string;
  setValue: (value: string) => void;
  placeholder: string;
  type: inputType;
  search?: boolean;
  label?: string;
  size: string;
  error?: string;
  password?: boolean;
  width?: string;
  disabled?: boolean;
  isForcusd?: boolean;
  defaultValue?: string;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
}

const Input = ({
  value,
  setValue,
  type,
  search,
  label,
  size,
  placeholder,
  error,
  password,
  width,
  disabled,
  isForcusd,
  defaultValue,
  inputMode,
}: inputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setForcused] = useState(false);
  const clearInput = () => {
    setValue("");
  };

  const handleBlur = () => {
    // 블러 이벤트 처리를 지연시켜 버튼 클릭 이벤트가 먼저 처리될 수 있도록 함
    setTimeout(() => {
      setForcused(false);
    }, 100); // 100ms 지연
  };

  useEffect(() => {
    // 컴포넌트가 마운트되면 input 요소에 포커스를 맞춥니다.
    if (isForcusd && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  if (type === "box") {
    return (
      <div className={`${width} flex-col justify-start  gap-1.5 inline-flex`}>
        {label && (
          <label
            className={`${
              size === "lg" ? TYPO.TYPO7_MD : TYPO.TYPO8_MD
            } pl-1 text-gray70`}
          >
            {label}
          </label>
        )}
        <div className={`flex relative ${width && width}`}>
          {search && (
            <div
              className={`absolute ${
                size === "lg" ? "top-[26px]" : "top-[21px]"
              } left-[8px] translate-y-[-50%] flex justify-center items-center w-[24px] h-[24px]`}
            >
              <Image
                alt="search"
                src={"/images/icons/search.svg"}
                height={18}
                width={18}
              />
            </div>
          )}
          <input
            autoFocus={isForcusd}
            inputMode={inputMode && inputMode}
            defaultValue={defaultValue && defaultValue}
            disabled={disabled}
            type={password ? "password" : "text"}
            value={value && value}
            onFocus={() => setForcused(true)}
            onBlur={handleBlur}
            onChange={(e) => setValue(e.target.value)}
            ref={inputRef}
            placeholder={placeholder}
            className={`box-input ${width && width} ${
              size === "lg" ? "h-[54px]" : "h-[44px]"
            }  px-3 py-2 bg-gray2 ${
              size === "lg" ? TYPO.TYPO5_MD : TYPO.TYPO6_MD
            } ${error && "error-box-input"} ${
              search ? "!pl-10" : "pl-3"
            } text-gray90 border border-slate-100 rounded-xl `}
          />

          {focused && value && value.length > 0 && (
            <div
              className="w-[30px] h-[30px] flex justify-center items-center z-30 x-btn absolute top-[50%] translate-y-[-50%] cursor-pointer right-[10px]"
              onClick={clearInput}
            >
              <Image
                alt="x"
                src={"/images/icons/x.svg"}
                height={20}
                width={20}
              />
            </div>
          )}
        </div>
        {error && (
          <span className={`${TYPO.TYPO8_MD} text-red40 pl-1`}>{error}</span>
        )}
      </div>
    );
  } else if (type === "line") {
    return (
      <div className={`${width} flex-col justify-start  gap-1.5 inline-flex`}>
        {label && (
          <label
            className={`${size === "lg" ? TYPO.TYPO7_MD : TYPO.TYPO8_MD}  ${
              error
                ? "text-[#FA4D56]"
                : focused
                ? "text-[#4589FF]"
                : "text-gray70"
            } `}
          >
            {label}
          </label>
        )}
        <div className={`flex  relative ${width}`}>
          {search && (
            <div
              className={`absolute ${
                size === "lg" ? "top-[26px]" : "top-[21px]"
              } left-[0px] translate-y-[-50%] flex justify-center items-center w-[24px] h-[24px]`}
            >
              <Image
                alt="search"
                src={"/images/icons/search.svg"}
                height={18}
                width={18}
              />
            </div>
          )}
          <input
            disabled={disabled}
            value={value && value}
            onFocus={() => setForcused(true)}
            onBlur={handleBlur}
            onChange={(e) => setValue(e.target.value)}
            ref={inputRef}
            placeholder={placeholder}
            className={`line-input ${width} py-2 ${
              size === "lg" ? "h-[52px]" : "h-[42px]"
            }   ${size === "lg" ? TYPO.TYPO5_MD : TYPO.TYPO6_MD} ${
              error && "error-line-input"
            } ${
              search ? "pl-8" : "pl-0"
            } text-zinc-800 border-b-2 border-zinc-200 `}
            style={{
              WebkitAppearance: "none",
              WebkitBorderRadius: 0,
            }}
          />
          {focused && (
            <div
              className="w-[30px] h-[30px] flex justify-center items-center z-30 x-btn absolute top-[50%] translate-y-[-50%] cursor-pointer right-[10px]"
              onClick={clearInput}
            >
              <Image
                alt="x"
                src={"/images/icons/x.svg"}
                height={20}
                width={20}
              />
            </div>
          )}
        </div>
        {error && (
          <span className={`${TYPO.TYPO8_MD} text-red40 pl-1`}>{error}</span>
        )}
      </div>
    );
  } else return <></>;
};

export default Input;
